import styles from './AnchorMenu.module.scss';

import { absoluteUrl, transformTitleId } from '@utils/functions/utils';
import { useEffect, useMemo, useRef, useState } from 'react';
import Button from '@components/atoms/CustomButton/CustomButton';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { useTranslation } from 'next-i18next';
import { AnchorMenuModel } from '@model/anchorMenu.model';
import GridContainer from '@atoms/GridContainer/GridContainer';
import Row from '@atoms/CustomGrid/Row';
import { useSocials } from '@hooks/useSocials';
import SocialShareButton from '@atoms/SocialShareButton/SocialShareButton';

const AnchorMenu = (node) => {
  const data = useMemo(() => {
    const newData: AnchorMenuModel = {
      title: node.node.title || '',
      path: node.node.path.alias || '/',
      nodeType: node.node.type.replace('node--', '') || 'page',
      linkProject: node.node.field_link?.uri || '',
      links: node.node.field_paragraphs
        .filter((item) => item.behavior_settings?.style_options?.toc?.label)
        .map((item) => ({
          title: item.behavior_settings?.style_options?.toc?.label || '',
        })),
    };

    return newData;
  }, [node]);

  const socials = useSocials({
    title: data?.title,
    url: absoluteUrl(data?.path),
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const [activeSection, setActiveSection] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const scrollRef = useRef(null);
  const dataType = {
    icon: 'TextsmsOutlined',
    type: '2',
    label: t('anchorMenuCta'),
    url: data?.nodeType == 'project' ? data?.linkProject : '',
  };
  const handleClick = (event, anchorId) => {
    event.preventDefault();
    const anchorTarget = document.querySelector(`[anchor-data=${anchorId}]`);
    anchorTarget?.scrollIntoView({ behavior: 'smooth', block: isMobile ? 'start' : 'center', inline: 'center' });
    // window.scrollTo({
    //   top: anchorTarget?.getBoundingClientRect().top,
    //   behavior: "smooth"
    // });
    // setActiveSection(anchorTarget);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (
        pageYOffset > window.innerHeight / 4 &&
        pageYOffset < document.querySelector('main').clientHeight - document.querySelector('footer').clientHeight
      ) {
        setIsScrolled(true);
      } else setIsScrolled(false);
      const idArray = data?.links?.filter((item) => item.title).map((item) => transformTitleId(item.title));
      const sections = idArray
        ?.filter((id) => document.querySelector(`[anchor-data=${id}]`))
        .map((id) => document.querySelector(`[anchor-data=${id}]`));
      let currentId = '';

      let winScroll = document.body.scrollTop || document.documentElement.scrollTop,
        height = document.documentElement.scrollHeight - document.documentElement.clientHeight,
        scrolled = -(winScroll / (height - document.querySelector('footer').clientHeight - 120)) * 100 + 100;
      if (scrollRef.current && scrolled > 0)
        scrollRef.current.style.backgroundPosition = -((scrolled * scrollRef.current.clientWidth) / 100) + 'px';

      sections?.forEach((section, index) => {
        const sectionTop = section instanceof HTMLElement && section.offsetTop;
        const sectionHeight = section.clientHeight;
        if (pageYOffset >= sectionTop - sectionHeight) {
          currentId = section.getAttribute('anchor-data');
          setActiveSection(currentId);
          setValue(index);
        }
      });
    };
    window.addEventListener('scroll', handleScroll);
  }, [data]);

  if (!data) return null;

  return (
    <Box
      component="nav"
      className={`${styles.anchorNav} ${isScrolled ? styles.anchorNavVisible : ''}`}
      sx={{ borderBottom: 1, borderColor: 'divider' }}
    >
      <GridContainer className={styles.hubDisovery}>
        <Row justifyContent="left">
          <Grid item xs={12} justifyContent="center" alignItems="center">
            <Box className={styles.anchorNavBoxWrap}>
              <Box ref={scrollRef} className={styles.anchorNavBoxScroll} />
              <Box className={styles.anchorNavBox}>
                {data.links?.length == 1 && !data.linkProject && data.nodeType != 'project' ? (
                  <span className={`${styles.anchor} ${styles.single}`}>{data.links[0].title}</span>
                ) : (
                  <Tabs value={value} indicatorColor="primary">
                    {data.links?.map(
                      (link, index) =>
                        link.title && (
                          <Tab
                            key={index}
                            onClick={(event) => handleClick(event, transformTitleId(link.title))}
                            // label={link.title.split(' ').slice(0, 2).join(' ')}
                            label={link.title}
                            href={`#${transformTitleId(link.title)}`}
                            className={`${styles.anchor} ${data.links.length == 1 ? styles.single : ''} ${
                              activeSection === transformTitleId(link.title) ? 'Mui-selected' : ''
                            }`}
                          />
                        )
                    )}
                  </Tabs>
                )}
                <Box className={styles.anchorNavBoxRight}>
                  <>
                    {!isMobile && data.links?.length > 1 && data.nodeType == 'project' && data.linkProject && (
                      <>
                        <Button
                          color="link"
                          variant="link"
                          text={t('anchorMenuCta')}
                          className="withIcon"
                          endIcon={<ArrowOutwardIcon />}
                          href={data.linkProject}
                          target="_blank"
                        />
                        <Divider orientation="vertical" variant="middle" flexItem />
                      </>
                    )}
                    <SocialShareButton socials={socials} dataType={dataType} />
                  </>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Row>
      </GridContainer>
    </Box>
  );
};

export default AnchorMenu;
